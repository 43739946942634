import React from "react"
import { Sportevent, getEventState } from "../../models/Sportevent"
import { Link } from "react-router-dom"
import getDateTimeText from '../../helpers/datetimetext'

type EventItemProps = {
  item: Sportevent
}

const EventItemComponent: React.FC<EventItemProps> = (props: EventItemProps) => {

  return (
    <article className={[getEventState(props.item)].join("")}>
      <header>
        {getDateTimeText(props.item.Begin, props.item.End, props.item.IsWholeMonth, props.item.IsWholeDay, true)}
      </header>
      <section>
        {
          getEventState(props.item) === 'registration-finished' && <strong style={{'display': 'block', 'margin': '.5em 0'}}>Anmeldung geschlossen.</strong>
        }
        {
          getEventState(props.item) === 'canceled' && <div className='canceled-text'>{props.item.CancelledText}</div>
        }
        <h2>{props.item.Title}</h2>
        <p>{props.item.Text}</p>
        {props.item.Place && !props.item.PlaceLink && <p className='place'>{props.item.Place}</p>}
        {props.item.Place && props.item.PlaceLink && <p className='place'><a href={props.item.PlaceLink} rel="noreferrer" target="_blank">{props.item.Place}</a></p>}
        {
          props.item.PriceEvent && <p className='info'>
            Kursbeitrag {props.item.PriceLocation ? 'inkl. Liftkarte' : ''}: € {(props.item.PriceEvent + (props.item.PriceLocation || 0)).toFixed(2)}
          </p>
        }
      </section>
      {
        getEventState(props.item) == 'registration' && <Link className="registrationlink" to={ `/anmeldung/${props.item.Identifyer}`}>Anmeldung</Link>
      }
    </article>
  )
}

export default EventItemComponent;