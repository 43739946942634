import React from "react"
import { Link, useLocation, useParams } from "react-router-dom"
// import AktuelleInformationen from "../components/footer-partials/AktuelleInformationen";
// import Haftungsausschluss from "../components/footer-partials/Haftungsausschluss";
// import Kontakt from "../components/footer-partials/Kontakt";
import RegistrationForm from "../components/registrationform/RegistrationForm";


const RegistrationPage = () => {

  const { eventid } = useParams();

  return(
    <main>
        {/* <section className="hero" style={{ backgroundImage: 'url("../hero.jpg")' }}></section> */}
        <section className='content-section'>
          <h2>
            <Link to="/">..</Link>/Anmeldung
          </h2>
          <RegistrationForm eventId={eventid} />
        </section>
      </main>
  )
}

export default RegistrationPage;