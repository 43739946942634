const Mitgliedschaft = () => {
  return (
    <section className='content-section'>
      <h2>Haftungsausschluss</h2>
      <p>
        Die Teilnahme an den Veranstaltungen ist freiwillig. Der Verein übernimmt keine Haftung
        für Unfälle oder für abhandengekommene Gegenstände. Irrtümer, Druckfehler und
        Änderungen bleiben vorbehalten.
      </p>
    </section>
  );
}

export default Mitgliedschaft;