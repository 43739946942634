import React from "react"
import { format } from "date-fns";

const timeStyle: React.CSSProperties = {
  fontSize: '.6em'
}

const getDateTimeText = (begin: Date, end: Date | undefined, isWholeMonth: boolean, isWholeDay: boolean, timeSmall: boolean = false): any => {
  let dateString = ""
    let timeString = ""

    if (isWholeMonth) {
      return `${format(begin, "yyyy, MMMM")}`;
    }
    else if (!end) {
      // Has No End Date
      dateString = `${format(begin, "yyyy, dd. MMMM")}`
      timeString = `${format(begin, "HH:mm")}`
    }
    else {
      // Has End Date

      let isSameMonth = format(begin, "yyyyMM") === format(end, "yyyyMM")
      let isSameDate = format(begin, "yyyyMMdd") === format(end, "yyyyMMdd")

      timeString = `${format(begin, "HH:mm")}-${format(end, "HH:mm")}`

      if (isSameDate) {
        dateString = `${format(begin, "yyyy, dd. MMMM")}`
      }
      else if (isSameMonth) {
        dateString = `${format(begin, "yyyy, ")} ${format(begin, "dd.")}-${format(end, "dd.")} ${format(end, "MMMM")}`
      } else {
        console.error("not implemented")
      }
    }

    return (
      <span><span>{dateString}</span> {!isWholeDay && <span style={timeSmall ? timeStyle : {}}> {timeString} Uhr</span>}</span>
    )
}

export default getDateTimeText