export type Membership = {
  ChildrenFee: number
  ChildrenText: string
  AdultFee: number
  AdultText: string
}

const membership: Membership = {
  ChildrenFee: 15,
  ChildrenText: "Kinder u. Jugendliche jünger als 18 Jahre",
  AdultFee: 15,
  AdultText: "Erwachsende ab 18 Jahre"
}

export default membership;
