import EventItem from "../components/events/EventItem";
import agenda from '../data/agenda';
import _ from 'lodash'
import currentSeason from "../data/currentSeason";
import { format, isBefore } from "date-fns";
import beginRegistrationDate from "../data/beginRegistrationDate";

const EventsPage = () => {
  return (
    <main>
      <section className="hero" style={{ backgroundImage: `url('./hero.jpg')` }}></section>

      <section className='content-section'>
        <h2>
          Termine {currentSeason}
        </h2>
        <p>Hier findet Ihr das aktuelle Programm!</p>
        {
          isBefore(new Date(), beginRegistrationDate) && 
          <strong className="card" style={
            {
              'backgroundColor': 'var(--color-dsv-green)',
              'display': 'block',
              'color': 'white',
              'textAlign': 'center',
              'fontFamily': 'monospace'
            }}>
            {/* <p style={{ margin: 0 }}>
              Liebe Skikurs-TeilnehmerInnen,
            </p> */}
            <p>
              Anmeldungen sind ab dem {format(beginRegistrationDate, "dd. MMMM HH:mm")} hier möglich.
            </p>
            <p>
              mit sportlichem Glück auf<br/>
              DSV Leoben Wintersport 
            </p>
          </strong>  
        }
      </section>
      <section className='agenda'>
        {
          _.filter(agenda, e => true).map(item => (
            <EventItem key={item.Id} item={item}></EventItem>
          ))
        }
      </section>
      {/* <section className='content-section'>
        <h2>Freeride und Touren BigPackage</h2>
        <p>
          Für alle Interessierten gibt's alle Touren und Freeridekurse auch als komplettes Set.
        </p>
      </section> */}
    </main>
  )
}

export default EventsPage;