export type SkillType = {
  type: 'kurs' | 'langlaufen',
  options: Skill[]
}

export type Skill = {
  key: string,
  value: string
}


const skillselection: SkillType[] = [
  {
    type: 'kurs',
    options: [
      {
        key: 'Anfänger',
        value: 'Anfänger'
      },
      {
        key: '1 Woche Kurs absolviert',
        value: '1 Woche Kurs absolviert'
      },
      {
        key: '3 Wochen Kurs absolviert',
        value: '3 Wochen Kurs absolviert'
      },
      {
        key: '5 Wochen Kurs absolviert',
        value: '5 Wochen Kurs absolviert'
      },
      {
        key: 'Mehr als 5 Wochen Kurs absolviert',
        value: 'Mehr als 5 Wochen Kurs absolviert'
      },
    ]
  },
  {
    type: 'langlaufen',
    options: [
      {
        key: 'Anfänger - weniger als eine Woche auf Langlaufski unterwegs',
        value: 'Anfänger - weniger als eine Woche auf Langlaufski unterwegs'
      },
      {
        key: 'Fortgeschritten - mehr als eine Woche auf Langlaufski unterwegs',
        value: 'Fortgeschritten - mehr als eine Woche auf Langlaufski unterwegs'
      }
    ]
  }
]

export default skillselection;
