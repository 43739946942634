import currentSeason from '../../data/currentSeason';
import membership from '../../data/membership'

const Mitgliedschaft = () => {
  return (
    <section className='content-section'>
      <h2>Vereinsbeitrag {currentSeason}</h2>
      <p>
        {membership.AdultText}: € {membership.AdultFee.toFixed(2)}; <br />
        {membership.ChildrenText}: € {membership.ChildrenFee.toFixed(2)}; 
      </p>
    </section>
  );
}

export default Mitgliedschaft;