const Mitgliedschaft = () => {
  return (
    <section className='content-section'>
      <h2>Aktuelle Informationen</h2>
      <p>
        Alle Informationen, Termine und Kosten in dieser
        Programmübersicht sind vorläufig und können sich ändern. Die
        aktuellsten Informationen gibt es auch auf <a href="http://www.facebook.com/DSV.Leoben.Wintersport" rel="noreferrer" target="_blank">Facebook/DSV.Leoben.Wintersport</a>.
      </p>
      <p>
        <a target="_blank" href="https://dsvstorage.blob.core.windows.net/dsv-website-assets/Skikursinfo.pdf">Saisonprogramm/Skikursinfos als PDf zum herunterladen.</a>
      </p>
    </section>
  );
}

export default Mitgliedschaft;