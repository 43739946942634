const Mitgliedschaft = () => {
  return (
    <section className='content-section'>
      <h2>Kontakt</h2>
      <p>
        Sollten Sie weiter Fragen zu unseren Aktivitäten haben, so können Sie uns gerne
        unter <a href="mailto:wintersport@dsv-leoben.org">wintersport@dsv-leoben.org</a> kontaktieren.
      </p>
    </section>

  );
}

export default Mitgliedschaft;