import React, { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import './form.css';
import * as _ from "lodash"

import axios from 'axios';

import agenda from '../../data/agenda'
import skillselection from '../../data/skillselection'
import membershipFeeDb from '../../data/membership'
import { Sportevent } from "../../models/Sportevent";
import { MailSendEventDto } from "../../models/MailSendEvent";
import currentSeason from "../../data/currentSeason";
import getDateTimeText from "../../helpers/datetimetext";

type RegistrationFormModel = {
  event: string

  priceEvent: number
  priceLocation: number
  priceMembership: number
  priceSum: number
  includingLocationPrice: boolean

  firstName: string
  lastName: string
  bday: string
  gender: string

  email: string
  phone: string

  street: string
  citycode: string
  city: string
  country: string

  skill: string

  hasAttended: boolean
  membershipPaid: boolean
  isMemberDsv: boolean
  memberidDsv: string

  isMemberOesv: boolean
  memberidOesv: string

  message: string

  confirmation: boolean
}

function getAge(dateString: string) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export type Props = {
  eventId: string | undefined
}

const RegistrationForm = (props: Props) => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const [ birthday, setBirthday ] = useState('')
  const [ membershipFee, setMembershipFee ] = useState(0)

  const [isIncludingLocationPrice , setIsIncludingLocationPrice ] = useState(true)
  const [isMemberDsv, setIsMemberDsv ]  = useState(false)
  const [isMembershipPayed , setIsMembershipPayed ]  = useState(false)
  const [isMemberOesv , setIsMemberOesv ]  = useState(false)

  const {
    register,
    handleSubmit,
    // watch,
    // control,
    setValue,
    // getFieldState,
    formState: {
      // isDirty,
      // isValid,
      errors
    },
    reset,
    getValues
  } = useForm<RegistrationFormModel>();

  const onSubmit = async (data: any, e: any) => {

    const event: Sportevent = selectedEvent!;

    let dto: MailSendEventDto = {
      identifyer: event.Identifyer,
      title: event.Title,
      text: event.Text,
      place: event.Place,
      placeLink: event.PlaceLink,
      saison: currentSeason,

      begin: event.Begin.toISOString(),
      isWholeMonth: event.IsWholeMonth,
      isWholeDay: event.IsWholeDay,
      end: event.End?.toISOString(),

      priceSum: getValues('priceSum'),

      priceEvent: event.PriceEvent!,
      priceLocation: event.PriceLocation,
      priceMembership: membershipFee,

      includingLocationPrice: getValues('includingLocationPrice'),

      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      birthday: getValues('bday'),
      gender: getValues('gender'),

      email: getValues('email'),
      phone: getValues('phone'),

      street: getValues('street'),
      citycode: getValues('citycode'),
      city: getValues('city'),
      country: getValues('country'),

      skill: getValues('skill'),
      hasAttended: getValues('hasAttended'),

      isMemberDsv: getValues('isMemberDsv'),
      memberIdDsv: getValues('memberidDsv'),
      membershipPaid: getValues('membershipPaid'),

      isMemberOesv: getValues('isMemberOesv'),
      memberIdOesv: getValues('memberidOesv'),

      message: getValues('message'),

      confirmation: getValues('confirmation')
    }

    setSending(true)

    console.info('send', dto)

    await axios.post('https://dsvpostalservice.azurewebsites.net/api/DsvPostalService?code=N5MdOrC4Oj3bPDe_7Qd_e2xq56d2eoXkXGxOHUfZz2ZZAzFugN6lPw==', dto);
    // await axios.post('http://localhost:7053/api/DsvPostalService', dto);

    setSending(false)

    reset()
    setSelectedEvent(undefined)
  };

  const onError = (errors: any, e: any)  => {
  }

  const [sending, setSending] = useState(false);

  const [selectedEvent, setSelectedEvent] = useState<Sportevent | undefined>(_.find(agenda, e => e.Identifyer === props.eventId))

  const getCorrectMembershipFee = () : number => {
    const currentBirthday: string | undefined = birthday

    const age = getAge(currentBirthday)

    let correctFee = 0
    if(!isMembershipPayed) {
      if(age >= 18) {
        correctFee = membershipFeeDb.AdultFee
      } else if(age < 18) {
        correctFee = membershipFeeDb.ChildrenFee
      }
    }
    return correctFee
  }

  const onBirthdayChanged = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const newValue: string = e?.target.value!;
    setBirthday(newValue)
    if(!isMembershipPayed){
      const fee = getCorrectMembershipFee()
      setMembershipFee(fee)
    }
  }

  const onEventChanged = (e: React.ChangeEvent<HTMLSelectElement> | undefined) => {
    const newEventKey: string = e?.target.value!;
    const newEvent = _.find(agenda, e => e.Identifyer === newEventKey)
    setSelectedEvent(newEvent)
  }

  const onIsMemberDsvChanged = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const newValue: boolean = e?.target.checked || false
    setIsMemberDsv(newValue)
  } 

  const onIsMemberOesvChanged = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const newValue: boolean = e?.target.checked || false
    setIsMemberOesv(newValue)
  }

  const onIncludingLocationPriceChanged = (e: React.ChangeEvent<HTMLInputElement> | undefined, checkedValue: number = 0, unCheckedValue: number = 0) => {
    const checked: boolean = e?.target.checked || false
    setValue('priceEvent', checked ? checkedValue : unCheckedValue)
    setIsIncludingLocationPrice(checked)
  }

  const onIsMembershipPayed = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const isPayed: boolean = e?.target.checked || false
    setIsMembershipPayed(isPayed)
  }

  useEffect(() => {
    const eventPrice = selectedEvent?.PriceEvent || 0
    const locationPrice = selectedEvent?.PriceLocation || 0
    const membershipPaid = isMembershipPayed

    const membershipFee = isMembershipPayed && birthday ? 0 : getCorrectMembershipFee()
    const includingLocationPrice = isIncludingLocationPrice

    let newSum = eventPrice

    if(!membershipPaid) {
      newSum += membershipFee
    }

    if(includingLocationPrice) {
      newSum += locationPrice
    }

    setValue('priceMembership', membershipFee)
    setValue('priceSum', newSum)
  }, [selectedEvent, isIncludingLocationPrice, isMembershipPayed, birthday])

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <h2>{selectedEvent?.Title}</h2>
      {selectedEvent?.Begin && <p style={{fontWeight: 'bold'}}>{getDateTimeText(selectedEvent?.Begin, selectedEvent?.End, selectedEvent?.IsWholeMonth, selectedEvent?.IsWholeDay)}</p>}
      <p>{selectedEvent?.Text}</p>
      {selectedEvent?.Place && !selectedEvent?.PlaceLink && <p className='place'>{selectedEvent?.Place}</p>}
      {selectedEvent?.Place && selectedEvent?.PlaceLink && <p className='place'><a href={selectedEvent?.PlaceLink} rel="noreferrer" target="_blank">{selectedEvent?.Place}</a></p>}

      {/* <div className="form-element-group">
        <label htmlFor="Veranstaltung">Bitte wählen Sie die Veranstaltung aus.</label>
        <select id="Veranstaltung" defaultValue={props.eventId} {...register("event", { required: true })} onChange={onEventChanged}>
          <option value="-" disabled>-</option>
          {
            (_.filter(agenda, a => a.RegistrationState === 'registration')).map(e => (
              <option key={e.Identifyer} value={e.Identifyer}>{e.Title}</option>
            ))
          }
        </select>
      </div> */}

      {/* {
        selectedEvent && <div className='card'>
          <h3>{selectedEvent.Title}</h3>
          <p>
            € {selectedEvent.PriceEvent?.toFixed(2)}
          </p>
          <h3>Konto</h3>
          <p>DSV Leoben Wintersport</p>
          <p>IBAN: AT75 2081 5244 0000 0071 bei der Steierm. Sparkasse (BIC: STSPAT2GXXX)</p>
        </div>
      } */}

      {
      selectedEvent && <>

          <fieldset>
            <legend>Persönliche Daten</legend>

            <div className={['form-element-group', errors.firstName ? 'error' : ''].join(" ")}>
              <label htmlFor="firstName">Vorname* { errors.firstName?.message }</label>
              <input autoComplete="given-name" id="firstName" type="text" defaultValue="" {...register("firstName", { required: 'Pflichtfeld' })} />
            </div>

            <div className={['form-element-group', errors.lastName ? 'error' : ''].join(" ")}>
              <label htmlFor="lastName">Nachname*</label>
              <input autoComplete="family-name" id="lastName" type="text" defaultValue="" {...register("lastName", { required: true })} />
            </div>

            <div className={['form-element-group', errors.bday ? 'error' : ''].join(" ")}>
              <label htmlFor="bday">Geburtstag*</label>
              <input autoComplete="bday" id="bday" type="date" {...register("bday", { required: true })} onChange={onBirthdayChanged}/>
            </div>

            <div className={['form-element-group', errors.gender ? 'error' : ''].join(" ")}>
              <label htmlFor="gender">Geschlecht</label>
              <select id="gender" {...register("gender", { required: true })}>
                <option value="Männlich">Männlich</option>
                <option value="Weiblich">Weiblich</option>
              </select>
            </div>

          </fieldset>

          <fieldset>
            <legend>Kontaktdaten</legend>

            <div className={['form-element-group', errors.email ? 'error' : ''].join(" ")}>
              <label htmlFor="email">E-Mail*</label>
              <input autoComplete="email" id="email" type="email" {...register("email", { required: true })} />
            </div>

            <div className={['form-element-group', errors.phone ? 'error' : ''].join(" ")}>
              <label htmlFor="phone">Telefonnummer* (Für Notfälle)</label>
              <input autoComplete="tel" id="phone" type="text" {...register("phone", { required: true })} />
            </div>

          </fieldset>


          <fieldset>
            <legend>Adresse</legend>

            <div className={['form-element-group', errors.street ? 'error' : ''].join(" ")}>
              <label htmlFor="street">Straße*</label>
              <input autoComplete="address-line1" id="street" type="text" {...register("street", { required: true })} />
            </div>
            <div className={['form-element-group', errors.citycode ? 'error' : ''].join(" ")}>
              <label htmlFor="citycode">Postleitzahl*</label>
              <input autoComplete="postal-code" id="citycode" type="text" {...register("citycode", { required: true })} />
            </div>
            <div className={['form-element-group', errors.city ? 'error' : ''].join(" ")}>
              <label htmlFor="city">Ort*</label>
              <input autoComplete="address-level2" id="city" type="text" {...register("city", { required: true })} />
            </div>
            <div className={['form-element-group', errors.country ? 'error' : ''].join(" ")}>
              <label htmlFor="country">Land*</label>
              <input autoComplete="country" id="country" type="text" defaultValue="Österreich" {...register("country", { required: true })} />
            </div>

          </fieldset>

          <fieldset>
            <legend>Erfahrung</legend>

            <div className={['form-element-group', errors.skill ? 'error' : ''].join(" ")}>
              <label htmlFor="Könnerstufe">Könnerstufe</label>
              <select id="Könnerstufe" {...register("skill", { required: true })}>
                {
                  _.find(skillselection, e => e.type === selectedEvent.SkillType)?.options.map(e => (
                    <option key={e.key} value={e.key}>{e.value}</option>
                  ))
                }
                {/* <option value="Anfänger">Anfänger</option>
                <option value="1 Woche Kurs absolviert">1 Woche Kurs absolviert</option>
                <option value="3 Wochen Kurs absolviert">3 Wochen Kurs absolviert</option>
                <option value="5 Wochen Kurs absolviert">5 Wochen Kurs absolviert</option>
                <option value="Mehr als 5 Wochen Kurs absolviert">Mehr als 5 Wochen Kurs absolviert</option> */}
              </select>
            </div>

            <div className={['form-element-group', errors.hasAttended ? 'error' : ''].join(" ")}>
              <input id="hasAttended" type="checkbox" {...register("hasAttended", { required: false })} />
              <label htmlFor="hasAttended">Bereits an einem DSV-Leoben Wintersport Kurs teilgenommen</label>
            </div>

          </fieldset>

          <fieldset>
            <legend>DSV Mitgliedschaft</legend>

            <div className={['form-element-group', errors.isMemberDsv ? 'error' : ''].join(" ")}>
              <input id="isMemberDsv" type="checkbox" {...register("isMemberDsv", { required: false })} onChange={onIsMemberDsvChanged} />
              <label htmlFor="isMemberDsv">Bereits Mitglied beim DSV-Leoben Wintersport</label>
            </div>

            {
              !isMemberDsv && <>
              <p>
                Bitte sende das ausgefüllte <a target="_blank" href="https://dsvstorage.blob.core.windows.net/dsv-website-assets/Antrag_auf_Aufnahme_DSV-Leoben-Windersport.pdf">Anmeldeformular</a> an <a href="mailto:wintersport@dsv-leoben.org">wintersport@dsv-leoben.org</a>!
              </p>
              </>
            }

            {
              isMemberDsv && <>
                <div className={['form-element-group', errors.memberidDsv ? 'error' : ''].join(" ")}>
                  <label htmlFor="memberidDsv">DSV Mitgliedsnummer*</label>
                  <input id="memberidDsv" type="text" {...register("memberidDsv", { required: false })} />
                </div>
                <div className="form-element-group">
                  <input id="membershipPaid" type="checkbox" 
                    {
                      ...register(
                        "membershipPaid", { required: false }
                      )
                    }
                    onChange={onIsMembershipPayed}/>
                  <label htmlFor="membershipPaid">Mitgliedsbeitrag für die Saison {currentSeason} bereits bezahlt.</label>
                </div>
              </>
            }

          </fieldset>

          <fieldset>
            <legend>ÖSV Mitgliedschaft</legend>

            <div className={['form-element-group', errors.isMemberOesv ? 'error' : ''].join(" ")}>
              <input id="isMemberOesv" type="checkbox" {...register("isMemberOesv", { required: false })} onChange={onIsMemberOesvChanged} />
              <label htmlFor="isMemberOesv">Bereits Mitglied beim Österreichischer Skiverband</label>
            </div>

            {
              isMemberOesv &&
              <div className={['form-element-group', errors.memberidOesv ? 'error' : ''].join(" ")}>
                <label htmlFor="memberidOesv">ÖSV Mitgliedsnummer</label>
                <input id="memberidOesv" type="text" {...register("memberidOesv", { required: isMemberOesv })} />
              </div>
            }

          </fieldset>

          <fieldset>
            <legend>Möchten Sie uns noch etwas mitteilen?</legend>
            <div className={['form-element-group', errors.message ? 'error' : ''].join(" ")}>
              <label htmlFor="message">Deine Nachricht an uns</label>
              <textarea id="message" rows={4} {...register("message", { required: false })} />
            </div>
          </fieldset>

          <fieldset>
            <legend>Kursbeitrag</legend>

            <div className="form-element-group">
              <label htmlFor="price">Kursbeitrag (€)</label>
              <input id="price" type="number" readOnly defaultValue={(selectedEvent.PriceEvent || 0) + (selectedEvent.PriceLocation || 0)} {...register("priceEvent", { required: true })} />
            </div>

            {
              selectedEvent.PriceLocation && <>
                <div className="form-element-group">
                  <input id="includingLocationPrice" type="checkbox" {...register("includingLocationPrice", { required: false, value: true })} onChange={(e) => onIncludingLocationPriceChanged(e, (selectedEvent.PriceEvent || 0) + (selectedEvent.PriceLocation || 0), selectedEvent.PriceEvent!)} />
                  {
                    selectedEvent.Place && selectedEvent.PlaceLink && <label htmlFor="includingLocationPrice">Inklusive Liftkarte für <a href={selectedEvent.PlaceLink}>{ selectedEvent.Place }</a>.</label>
                  }
                  {
                    selectedEvent.Place && !selectedEvent.PlaceLink && <label htmlFor="includingLocationPrice">Liftkarte für { selectedEvent.Place }.</label>
                  }
                  {
                    !selectedEvent.Place && <label htmlFor="includingLocationPrice">Liftkarte</label>
                  }
                </div>

                {/* {
                  isIncludingLocationPrice && <>
                    <div className="form-element-group">
                      <label htmlFor="priceLocation">Liftkarte (€)</label>
                      <input id="priceLocation" type="number" defaultValue={selectedEvent.PriceLocation} {...register("priceLocation", { required: true, disabled: true })} />
                    </div>
                  
                  </>
                } */}


              </>
            }


            <div className="form-element-group">
              <label htmlFor="priceMembership">Mitgliedsbeitrag (€)</label>
              <input id="priceMembership" type="number" readOnly 
                defaultValue={membershipFee}
                {
                    ...register(
                      "priceMembership",
                      { required: true, value: membershipFee }
                    )
                } />
            </div>

            <hr/>

            <div className="form-element-group" style={{'fontWeight': 'bold'}}>
              <label htmlFor="priceSum">Summe Kosten (€)</label>
              <input id="priceSum" type="number" readOnly
                {
                  ...register(
                    "priceSum",
                    { required: true, value: membershipFee }
                  )
                } />
            </div>

          </fieldset>

          <hr />

          <div className={['form-element-group', errors.confirmation ? 'error' : ''].join(" ")}>
            <input style={{ 'minWidth': '1rem' }} id="confirmation" type="checkbox" {...register("confirmation", { required: true })} />
            <label htmlFor="confirmation">Ich zeige mich damit einverstanden, dass meine Daten für Vereinszwecke gespeichert werden und stimmen weiters der Zusendung von Vereinsinformationen, Marketingmaterial, Newslettern, usw. via E-Mail zu. Der Verein wird die Daten ausschließlich im Rahmen der Vereinstätigkeit verwenden und nicht an Dritte weitergeben.</label>
          </div>

          <hr />
          <strong>Herzlichen Dank für das Interesse an unserem Vereinsangebot.</strong>

          <p>
            Die Anmeldung erlangt erst Gültigkeit mit der Anmeldebestätigung und der Vereinsanmeldung, welche wir nach Überweisung der Kurskosten inkl. dem Mitgliedsbeitrag auf unser Konto zusenden.
          </p>

          <p>Mit sportlichem Glück Auf</p>
          <p>Das Team des DSV Leoben</p>

          <input type="submit" value={!sending ? "Anmeldung abschicken" : "Anmeldung wird verschickt ..."} />

        </>
      }
    </form>
  )
}

export default RegistrationForm;